@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri&family=Poppins:ital,wght@1,500&family=Rowdies:wght@700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wrapper{
    background-color: #FCAF63;
    width: 100%;
}
.part-second{
    background-color: #00C6FF;
    padding-bottom: 150px;
    width: 100%;
    position: relative;
}
.mobile-main{
        display: none;
    }
.part-second::after{
   content: " ";
    position: absolute;
    top: 100%;
    left: 0;
    background: url('./assets/bg2.png') no-repeat;
    width: 100%;
    height: 223px;
    background-size: 100%;
}
.main-container{
    max-width: 1280px;
    margin: 0 auto;
    // background-color: green;
}
.banner{
    display: flex;
    flex-wrap: wrap;
    padding-top: 3rem;
}
.right-side-banner .union {
    background-image: url('./assets/Union2.png');
    max-height: 90px;
    padding: 18px;
    margin-right: -72px;
    // border: 2px solid #00C6FF;
    background-position: center;
    margin-top: 10px;
}
.left-side-banner , .right-side-banner{
    width: 50%;
    .taddy-image{
        max-width: 100%;
        max-height: 404px;

    }
}
.left-side-banner h1{
    color: black;
font-size: 40px;
font-family: Nunito;
font-weight: 900;
text-transform: uppercase;
line-height: 50px;
word-wrap: break-word
}
.left-side-banner p{
    color: black;
    font-size: 16px;
    font-family: Nunito;
    font-weight: 400;
    line-height: 22px;
    word-wrap: break-word
}
.taddy-tail{
    max-width: 100%;
}
.right-side-banner{

        position: relative;
        display: flex;

}

// second section
.playstoreheading{
    text-align: center;
    margin: 5px 0;
}
.second-section{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.second-right{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.rating{
    width: 158px;
    background: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: 1.5px #00C6FF solid;
    -webkit-backdrop-filter: blur(3.82px);
    backdrop-filter: blur(3.82px);
    padding: 20px 10px;
    text-align: center;
    margin: 0 5px;
    // cursor: pointer;
    h3{
        font-size: 1.8rem;
    }
    p{
        font-size: 1rem;
    }
}

@media (min-width:992px) {
    .second-section{
        flex-direction: row;
    }
    .second-left{
        width: 100%;
    }
    .second-right{
        width: 50%;
    }
    .right-side-banner{
        justify-content: flex-end;
    }
    .rating h3 {
        font-size: 30px;
    }
    .rating p {
        font-size: 16px;
    }
    .playstoreheading{
        text-align: left;
    }
}
// third section
.third-section{
    text-align: center;
    margin-top: 50px;
    h1{
        color: #00CFFF;
        font-size: 60px;
        font-family: Rowdies;
        font-weight: 700;
        line-height: 50px;
        word-wrap: break-word;
        -webkit-text-stroke: .4rem #000048;
    }
    p{
        margin: 0 10px;
    }
}

// fourth section start
.fourth-section{
    display: flex;
    width: 100%;
    margin-bottom: 76px;
}

@media (max-width: 992px) {
    .fourth-section {
        justify-content: center;
        flex-wrap: wrap;
    }
  }
@media(min-width:992px){
    
}
  @media (max-width: 768px) {
    .fourth-section{
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }
    .left-side-banner,
    .right-side-banner,
    .right-side-banner {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      justify-content: center;
    }
  
    .left-side-banner h1 {
      font-size: 30px;
      line-height: 40px;
    }
  }
  
.left-fourth-section {
    display: flex;
    justify-content: center;
    max-width: 477px;
    max-height: 495px;
    // margin: 0 131px;
}
.right-side-fouth-section{
//  background-color: red; 
display: flex;
// max-width: 640px;
flex-wrap: wrap;
gap: 1rem;
align-items: center;
justify-content: center;
// max-width: 50%;
}

@media (max-width: 768px) {
    .right-side-fouth-section{
        max-width: 100%;
    }
    .taddy-image{
        margin-top: 2rem;
    }
    .union{
        max-width: 247px;
        max-height: 90px;
        position: absolute;
        left: 0;
        top: -44px;
    }
}

.card {
    width: 328px;
    // max-width: 100%;
    height: 204.7px;
    border-radius: 8px;
    background-image: url('./assets/Group.png');
    background-size: cover;
    color: black;
    padding: 56px 20px;
    display: block;
    h2 {
        font-size: 16px;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        line-height: 1.5;
      }
  }
.card2 {
    background-image: url('./assets/Group2.png');
    h2{
        padding-left: 26px;
    }
    p{
        // transform: translate(50%, 50%);
        text-align: center;
    }
}

// part seond

.second-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.part-second-left{
    max-width: 640px;
    text-align: center;
    h1{

        font-family: Rowdies;
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: 78px;
        -webkit-text-stroke: 0.4rem #000048;
        color:#FFDC00;
    }
    p{
        color: #000;
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        padding: 0 20px;
    }
}



// CardComponent.scss
.features-container{
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    margin-top: 45px;
    justify-content: center;
}
.feature-card {
    background-image: url('./assets/features.png'); // Replace with your image URL
    background-size: cover;
    background-position: center;
    width: 303px; // Adjust the width and height as needed
    height: 95.5px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .card-text {
    //   color: #000000; // Adjust text color as needed
    //   text-align: center;
    //   font-size: 18px;
    //   font-weight: bold;
    //   cursor: pointer;
    //   color: #000;

    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 50px; /* 250% */
    text-transform: uppercase
    }
  }

  .part-second-right{
    display: none;
  }
 @media (min-width: 1024px) {
        .part-second-right{
            display: flex;
            position: relative;
            max-width: 640px;

        }
        .mobile1 {
            left: -80px;  // Adjust as needed
            max-width: 80%;  // Adjust as needed
        }

        .mobile2 {
            left: 20px;  // Adjust as needed
            max-width: 70%;  // Adjust as needed
        }

        .mobile3 {
            left: -20px;  // Adjust as needed
            top: 300px;  // Adjust as needed
        }

        .mobile4 {
            right: 20px;  // Adjust as needed
            max-width: 60%;  // Adjust as needed
        }
    }




@media (max-width: 768px) {
    .left-side-banner,
    .right-side-banner,
    .right-side-banner {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      justify-content: center   ;
    }
    .left-side-banner h1 {
      font-size: 30px;
      line-height: 40px;
  }

}
.testimonials{
    text-align: center;
    h2{
        color: #000;
        font-family: Nunito;
        font-size: 30px;
        font-style: normal;
        font-weight: 900;
        line-height: 50px; /* 166.667% */
        text-transform: uppercase;
    }
    h1{
        color: #00CFFF;
        // font-size: 60px;
        font-size: 7rem;
        font-family: Rowdies;
        font-weight: 700;
        line-height: 50px;
        word-wrap: break-word;
        -webkit-text-stroke: 0.4rem #000048;
    }

}

.crousel{
    margin: 72px 100px;
}


.mobile-main{
    position: absolute;
    right: 0;
    top: 0;
    width: 670px;
}


@media all and (min-width: 1200px) {
    .mobile-main{
        display: block;
    }
    .second-container{
    justify-content: start;
}

}

.testimonials {
    padding-top: 223px;
}
@media (max-width:992px) {
 .banner{
    flex-direction: column;
    align-items: center;
 }
 .left-side-banner{
    width:100% ;
    text-align: center;
    h1{
        text-align: center;
        margin: 0 10%;
    }
    p{
        text-align: center;
        margin: 0 5%;
    }
 }
 .right-side-banner{
    width: 100%;
    justify-content: center;
    margin-top: 32px;
 }
}